<template lang="pug">
v-card(light, flat)
    v-row
        v-col
            .text-h4 Training
    v-row
        v-col
            .text-h5 Categories
        v-spacer
        v-col.flex-shrink-1.flex-grow-0
            v-btn(color='brand', outlined, rounded, @click='openNewCatDialog') New Category
                v-icon(right) add
    v-row
        v-col.d-flex.flex-wrap
            template(v-for='category in categories')
                v-hover(v-slot='{hover}')
                    CategoryCard.mr-2.mb-2(:key='category.id', :name='category.name', :color='category.color', :icon='category.icon', isPreview, :description='category.description')
                        template(v-slot:cardActionButtons, v-if='hover')
                            v-btn(text, @click='editCategory(category)') Edit
    v-row
        v-col
            .text-h5 Resources
        v-spacer
        v-col.flex-shrink-1.flex-grow-0
            v-btn(color='brand', outlined, rounded, @click='openNewResourceDialog') Add new Item
                v-icon(right) add
    v-row
        v-col.d-flex
            span.mr-2.mt-5 Filters:
            v-checkbox.mr-2(label='Categorized', light, color='brand', v-model='resourceFilters.categorized')
            v-checkbox.mr-2(label='Uncategorized', light, color='brand', v-model='resourceFilters.uncategorized')
            v-select.mt-3(outlined, color='brand', hide-details, dense, label='Category', v-model='resourceFilters.selectedCategories', :items='categories', item-text='name', item-value='id', multiple)

    v-row
        v-col
            v-data-table(:items='filteredResources', :headers='resourceHeaders', @click:row='editResource', width='100vw')
                template(v-slot:item.icon='{item}')
                    v-icon.trainingIcon {{ item.icon }}
                template(v-slot:item.trainingCategoryId='{item}')
                    span {{ getCategoryNameFromId(item.trainingCategoryId) }}
                    span.error(v-if='!item.trainingCategoryId', title='This item will be hidden from the portal until it is categorized.') No Category

    v-row
        v-col
            .text-h5 Documents
        v-spacer
        v-col.flex-shrink-1.flex-grow-0
            v-btn(color='brand', outlined, rounded, @click='openNewDocumentDialog') Add new Document
                    v-icon(right) add
    v-row
        v-col.d-flex
            v-chip.mr-2(v-for='document in documents', :key='document', :href='getPdfLink(document)', target='_blank', close, @click:close='setFileToDelete(document)') {{ document }}
            

    
    v-dialog(v-model='categoryEditDialog', max-width='700')
        v-card(light)
            v-card-title Edit Category
            v-card-text
                v-form(ref='categoryForm')
                    v-row
                        v-col
                            v-text-field(outlined, color='brand', dense, hide-details, label='Name', v-model='categoryInEditMode.name')
                    v-row
                        v-col
                            v-text-field(outlined, color='brand', dense, hide-details, label='Description', v-model='categoryInEditMode.description')
                    v-row
                        v-col
                            v-color-picker(mode='hexa', label='Color', v-model='categoryInEditMode.color')
            v-card-actions
                v-spacer
                v-btn(text, @click='categoryEditDialog = false') Cancel
                v-btn.white--text(color='red', v-if='categoryInEditMode.id', @click='deleteCategory') Delete
                v-btn.white--text(color='brand', @click='saveCategory') Save

    v-dialog(v-model='resourceEditDialog', max-width='850')
        v-card(light)
            v-card-title Edit Resource
            v-card-text
                v-form(ref='resourceForm')
                    v-row
                        v-col.d-flex
                            v-text-field.mr-2(outlined, color='brand', dense, hide-details, label='Name', v-model='resourceInEditMode.name')
                            v-select.mr-2(outlined, color='brand', dense, hide-details, label='Category', v-model='resourceInEditMode.trainingCategoryId', :items='categories', item-text='name', item-value='id')
                            v-autocomplete.mr-2(outlined, color='brand', dense, hide-details, label='Icon', v-model='resourceInEditMode.icon', :items='iconOptions', :append-outer-icon='resourceInEditMode.icon')
                                template(v-slot:item='{item}')
                                    span {{ item }}
                                    v-spacer
                                    v-icon {{ item }}
                    v-row
                        v-col.d-flex
                            v-text-field.mr-2(outlined, color='brand', dense, hide-details, label='YouTube Video ID', v-model='resourceInEditMode.videoId')
                            v-text-field.mr-2(outlined, color='brand', dense, hide-details, label='Video Title', v-model='resourceInEditMode.videoTitle')
                            a.brand--text.mt-2(v-if='resourceInEditMode.videoId', :href='getVideoLink(resourceInEditMode.videoId)', target='_blank') Preview Video 
                    v-row
                        v-col.d-flex
                            v-select.mr-2(outlined, color='brand', dense, hide-details, label='PDF File Name', v-model='resourceInEditMode.pdfFile', :items='documents')
                            v-text-field.mr-2(outlined, color='brand', dense, hide-details, label='PDF File Title', v-model='resourceInEditMode.pdfTitle')
                            a.brand--text.mt-2(v-if='resourceInEditMode.pdfFile', :href='getPdfLink(resourceInEditMode.pdfFile)', target='_blank') Preview Doc
                    v-row
                        v-col.d-flex
                            v-text-field.mr-2(outlined, color='brand', dense, hide-details, label='Documentation Link', v-model='resourceInEditMode.docsLink')
                            a.brand--text.mt-2(v-if='resourceInEditMode.docsLink', :href='getDocsLink(resourceInEditMode.docsLink)', target='_blank') Go To Docs

            v-card-actions
                v-spacer
                v-btn(text, @click='resourceEditDialog = false') Cancel
                v-btn.white--text(color='brand', @click='saveResource') Save

    v-dialog(v-model='documentDialog', max-width='500')
        v-card(light)
            v-card-title Add Document
            v-card-text
                v-form(ref='documentForm')
                    v-row
                        v-col
                            v-file-input(clearable, label='Select File', @change='fileSelected', color='brand', outlined, dense, :loading='uploading', :disabled='uploading', v-model='selectedFile', accept='.pdf')
            v-card-actions
                v-spacer
                v-btn(text, @click='documentDialog=false') Close
                v-btn.white--text(@click='uploadFile', color='brand') Upload

    v-dialog(v-model='deleteDocumentDialog', max-width='500')
        v-card(light)
            v-card-title Delete Document
            v-card-text
                span Are you sure you want to delete this document? This action cannot be undone.
                br
                span File to be deleted:
                span.font-weight-bold {{ fileToDelete }}
            v-card-actions
                v-spacer
                v-btn(text, @click='deleteDocumentDialog=false') Cancel
                v-btn.white--text(color='red', @click='deleteFile') Delete

    v-snackbar(v-model='showAlert', dark, :timeout='3000') {{ snackbarMessage }}

</template>
<script>
import {cloneDeep} from 'lodash';
import CategoryCard from '@/components/portal/trainingcategorycard';

export default {
    components: {
        CategoryCard
    },
    props: {
    },
    data () {
        return {
            loading: true,
            categories: [],
            resources: [],
            documents: [],
            iconOptions: [],
            categoryEditDialog: false,
            newCategory: {
                name: '',
                description: '',
                color: '#000000'
            },
            categoryInEditMode: {},
            newResource: {
                name: '',
                videoId: '',
                videoTitle: '',
                icon: '',
                pdfFile: '',
                pdfTitle: '',
                docsLink: '',
                trainingCategoryId: ''
            },
            resourceInEditMode: {},
            resourceEditDialog: false,
            resourceFilters: {
                categorized: true,
                uncategorized: true,
                selectedCategories: []
            },
            documentDialog: false,
            uploading: false,
            selectedFile: null,
            deleteDocumentDialog: false,
            fileToDelete: null,
            snackbarMessage: ''
        };
    },
    computed: {
        resourceHeaders () {
            const headers = [
                {
                    text: 'Name',
                    value: 'name',
                    width: 250
                },
                {
                    text: 'Icon',
                    value: 'icon',
                    width: 48
                },
                {
                    text: 'Video Title',
                    value: 'videoTitle'
                },
                {
                    text: 'PDF File Title',
                    value: 'pdfTitle'
                },
                {
                    text: 'Documentation Link',
                    value: 'docsLink'
                },
                {
                    text: 'Category',
                    value: 'trainingCategoryId'
                }
            ];
            return headers;
        },
        filteredResources () {
            const resources = [];
            for (const resource of this.resources) {
                let include = false;
                if (this.resourceFilters.uncategorized && !resource.trainingCategoryId) {
                    include = true;
                }
                if (this.resourceFilters.categorized && resource.trainingCategoryId) {
                    if (this.resourceFilters.selectedCategories.length) {
                        include = this.resourceFilters.selectedCategories.includes(resource.trainingCategoryId);
                    } else {
                        include = true;
                    }
                }

                if (include) {
                    resources.push(resource);
                }
            }
            return resources;
        },
        showAlert: {
            get () {
                return this.snackbarMessage !== '';
            },
            set (val) {
                if (val === false) {
                    this.snackbarMessage = '';
                }
            },
        },
    },
    methods: {
        async fetchData () {
            const allData = await this.post('getTrainingResources');
            this.categories = allData.categories;
            this.resources = allData.resources;
            this.documents = allData.documents;
            this.iconOptions = allData.iconOptions;
        },
        openNewCatDialog () {
            this.categoryInEditMode = this.newCategory;
            this.categoryEditDialog = true;
        },
        async saveCategory () {
            const action = this.categoryInEditMode.id ? 'updateTrainingCategory' : 'createTrainingCategory';
            const result = await this.sendCommand({
                action,
                parameters: this.categoryInEditMode
            });
            if (result) {
                await this.fetchData();
                this.categoryEditDialog = false;
            } else {
                this.snackbarMessage = 'There was an error saving the category.';
            }
        },
        editCategory (category) {
            this.categoryInEditMode = category;
            this.categoryEditDialog = true;
        },
        async deleteCategory () {
            const result = await this.sendCommand({
                action: 'deleteTrainingCategory',
                parameters: this.categoryInEditMode.id
            });
            if (result) {
                await this.fetchData();
                this.deletingCategory = false;
                this.categoryEditDialog = false;
            } else {
                this.snackbarMessage = 'There was an error deleting the category';
            }
        },
        getCategoryName (id) {
            if (!id) {
                return null;
            }
            return this.categories.find(category => category.id === id).name;
        },
        openNewResourceDialog () {
            this.resourceInEditMode = this.newResource;
            this.resourceEditDialog = true;
        },
        editResource (resource) {
            this.resourceInEditMode = cloneDeep(resource);
            this.resourceEditDialog = true;
        },
        async saveResource () {
            const action = this.resourceInEditMode.id ? 'updateTrainingResource' : 'createTrainingResource';
            const result = await this.sendCommand({
                action,
                parameters: this.resourceInEditMode
            });
            if (result) {
                await this.fetchData();
                this.resourceEditDialog = false;
            } else {
                this.snackbarMessage = 'There was an error saving the training resource.';
            }
        },
        getCategoryNameFromId (id) {
            if (!id) {
                return '';
            }
            return this.categories.find(cat => cat.id === id).name;
        },
        getPdfLink (fileName) {
            const baseUrl = this.$store.state.portalBaseUrl;
            const trainingDocsPath = 'portal/trainingdocs/';
            return `${baseUrl}${trainingDocsPath}${fileName}`;
        },
        getDocsLink (path) {
            const baseUrl = this.$store.state.portalBaseUrl;
            return `${baseUrl}docs/${path}`;
        },
        getVideoLink (videoId) {
            return `https://www.youtube.com/watch?v=${videoId}`;
        },
        openNewDocumentDialog () {
            this.documentDialog = true;
        },
        fileSelected (file) {
            this.selectedFile = file;
        },
        async uploadFile () {
            this.uploading = true;
            const requestData = new FormData();
            requestData.append('attachment', this.selectedFile);
            requestData.append('fileName', this.selectedFile.name);
            const results = await this.post(
                'uploadTrainingDocument',
                requestData
            );
            if (results) {
                console.log('uploaded successfuly');
            }
            await this.fetchData();
            this.documentDialog = false;
            this.uploading = false;
        },
        setFileToDelete (file) {
            this.fileToDelete = file;
            this.deleteDocumentDialog = true;
        },
        async deleteFile () {
            const results = await this.post(
                'deleteTrainingDocument',
                {name: this.fileToDelete}
            );
            if (results) {
                this.deleteDocumentDialog = false;
                await this.fetchData();
            }
        }
    },
    watch: {
        categoryEditDialog (visible) {
            if (!visible) {
                this.categoryInEditMode = {};
                this.$refs.categoryForm.reset();
            }
        },
        resourceEditDialog (visible) {
            if (!visible) {
                this.resourceInEditMode = {};
                this.$refs.resourceForm.reset();
            }
        }
    },
    mounted () {
        window.trainingvm = this;
        this.fetchData();
    }
};
</script>

<style>
.trainingIcon {
    width: 48px !important;
}
</style>
